"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _Disqus = _interopRequireDefault(require("./components/Disqus"));

exports.Disqus = _Disqus.default;

var _CommentCount = _interopRequireDefault(require("./components/CommentCount"));

exports.CommentCount = _CommentCount.default;

var _CommentEmbed = _interopRequireDefault(require("./components/CommentEmbed"));

exports.CommentEmbed = _CommentEmbed.default;

var _Recommendations = _interopRequireDefault(require("./components/Recommendations"));

exports.Recommendations = _Recommendations.default;
var _default = _Disqus.default;
exports.default = _default;